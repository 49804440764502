<template>
  <div
    :class="'text-center d-flex flex-column justify-content-center align-items-center py-' + paddingY"
  >
    <b-spinner variant="primary" />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  props: {
    paddingY: {
      type: Number,
      default: 5,
      required: false,
    },
  },
}
</script>
