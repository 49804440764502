<template>
  <b-card
    v-if="data"
    class="earnings-card"
    :title="data.title"
  >
    <!-- chart -->
    <vue-apex-charts
      ref="chart"
      height="300"
      :options="dynamicoptions"
      :series="data.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const totalLabel = this.data.labels.length ? this.data.labels[0] : ''
    const totalValue = this.data.series.length ? `${this.data.series[0]}%` : ''
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            toolbar: {
              show: true,
            },
            events: {
              animationEnd(chartContext) {
                chartContext.toggleDataPointSelection(0)
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [2, -3, 8],
          labels: this.data.labels,
          stroke: { width: 0 },
          colors: this.data.colors,
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              // startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 0,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 0,
                    label: totalLabel,
                    formatter() {
                      return totalValue
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
  computed: {
    dynamicoptions() {
      const options = { ...this.earningsChart.chartOptions }
      options.labels = this.data.labels
      return options
    },
  },
}
</script>
