<template>
  <div>

    <b-row>
      <b-col cols="6">
        <b-card>
          <b-card-title>Structured product yield trends</b-card-title>
          <b-card-sub-title class="mb-1">
            Yield averages versus index and volatility
          </b-card-sub-title>
          <vue-apex-charts
            type="line"
            height="400"
            :options="regressionOptions"
            :series="regressionSeries"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-row
      v-for="trend in distributorTrendSeries"
      :key="trend.title"
    >
      <b-col cols="6">
        <b-card>
          <b-card-title>{{ trend.title }}</b-card-title>
          <b-card-sub-title class="mb-1">
            Matured products and live products by expected maturity date
          </b-card-sub-title>
          <vue-apex-charts
            type="bar"
            height="300"
            :options="distributorTrendOptions"
            :series="trend.series"
          />
        </b-card>
      </b-col>
    </b-row>

    <div class="mb-2">
      <b-button @click="testSubIndexList">
        get subindex list
      </b-button>
    </div>

    <div class="mb-2">
      {{ testSubIndexListResult }}
    </div>

    <div class="mb-2">
      <b-button @click="testSubIndexData">
        get subindex data
      </b-button>
    </div>

    <div class="mb-2">
      {{ testSubIndexDataResult }}
    </div>

    <hr>

    <b-button
      :disabled="testDownloadProductReportPdfDisabled"
      @click="testDownloadProductReportPdf"
    >
      Test download product report PDF
    </b-button>

    <b-button
      :disabled="testDownloadPortfolioReportPdfDisabled"
      @click="testDownloadPortfolioReportPdf"
    >
      Test download portfolio report PDF
    </b-button>

    <hr>

    <div class="mb-2">
      <b-button @click="testSpreadsheetUploadPrepare">
        Spreadsheet upload - prepare
      </b-button>
    </div>

    <div class="mb-2">
      {{ spreadsheetUploadPrepareResult }}
    </div>

    <div class="mb-2">
      <b-button @click="testSpreadsheetUploadPerform">
        Spreadsheet upload - perform
      </b-button>
    </div>

    <div class="mb-2">
      {{ spreadsheetUploadPerformResult }}
    </div>

    <hr>

    <b-button @click="testCreatePortfolio">
      Create portfolio
    </b-button>

    <hr>

    LFC test1
    <input
      v-model="first"
      type="text"
      @change="changed"
    >
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-pie-chart :data="underlyings" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <simple-pie-chart :data="underlyings" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <input
          v-model="scale"
          type="text"
          @change="changed2"
        >
        <simple-line-chart :data="linedata" />
      </b-col>
    </b-row>

    <b-card>
      <vue-apex-charts
        type="bubble"
        height="400"
        :options="bubbleOptions"
        :series="bubbleSeries"
      />
    </b-card>
    <b-card>
      <vue-apex-charts
        type="scatter"
        height="400"
        :options="scatterOptions"
        :series="scatterSeries"
      />
    </b-card>
  </div>
</template>

<script>
import { $themeColors, $chartColors } from '@themeConfig'
import {
  BRow, BCol, BCard, BCardTitle, BCardSubTitle, BButton,
} from 'bootstrap-vue'
// import Chart from 'highcharts-vue'
import VueApexCharts from 'vue-apexcharts'
import dayjs from 'dayjs'
import EcommercePieChart from './ecommerce/EcommercePieChart.vue'
import SimplePieChart from './components/SimplePieChart.vue'
import SimpleLineChart from './components/SimpleLineChart.vue'

export default {
  components: {
    EcommercePieChart,
    SimplePieChart,
    SimpleLineChart,
    //   highcharts: Chart,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BButton,
    VueApexCharts,
  },
  data() {
    return {
      baseRegressionOptions: {
        chart: {
          type: 'line',
          toolbar: {
            show: false,
          },
          zoom: {
            type: 'xy',
            enabled: false,
          },
        },
        colors: [$themeColors.warning, $chartColors[1], $themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.info,
          $chartColors[2], $chartColors[3], $chartColors[4], $chartColors[0]],
        stroke: {
          width: 3,
          curve: 'smooth',
          dashArray: [4, 4],
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
        },
        tooltip: {
          x: {
            formatter(val) {
              return dayjs(val).format('YYYY-MM-DD')
            },
          },
          shared: false,
          marker: false,
        },
        xaxis: {
          type: 'datetime',
          axisTicks: {
            show: true,
          },
          tooltip: {
            enabled: true,
            formatter(val) {
              return dayjs(val).format('YYYY-MM-DD')
            },
          },
          labels: {
            show: true,
            showDuplicates: false,
            formatter(val) {
              return dayjs(val).format('YYYY-MM-DD')
            },
          },
        },
        // yaxis options are set in regressionOptions computed prop
      },
      regressionSeries: [],
      baseDistributorTrendOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: [$themeColors.primary, $themeColors.success, $themeColors.warning, $themeColors.danger, $themeColors.info],
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          x: {
            formatter(val) {
              return dayjs(val).format('MM/YYYY')
            },
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter(val) {
              return dayjs(val).format('MM/YYYY')
            },
          },
        },
        yaxis: {
          title: {
            text: 'Sales volune (USDm)',
          },
          decimalsInFloat: 0,
        },
      },
      distributorTrendSeries: [],
      testSubIndexListResult: '',
      testSubIndexDataResult: '',
      testDownloadProductReportPdfDisabled: false,
      testDownloadPortfolioReportPdfDisabled: false,
      spreadsheetUploadPrepareResult: '',
      spreadsheetUploadPerformResult: '',
      bubbleOptions: {
        plotOptions: {
          bubble: {
            // maxBubbleRadius: 5,
          },
        },
        xaxis: {
          min: 1,
          max: 4,
        },
        yaxis: {
          min: 1,
          max: 4,
        },
      },
      bubbleSeries: [
        {
          data: [
            [2, 2, 5],
            [3, 3, 10],
          ],
        },
      ],
      scatterOptions: {
        markers: {
          size: 8,
        },
      },
      scatterSeries: [
        {
          data: [
            [1, 1],
            [2, 2],
            [3, 3],
            [4, 4],
            [5, 5],
          ],
        },
      ],
      first: 30,
      scale: 0,
      underlyings: {
        title: 'Underlyings',
        labels: ['S&P-500', 'Russell 2000', 'Apple', 'Nasdaq 100', 'Other'],
        series: [30, 25, 20, 10, 15],
      },
      linedata:
      {
        series: [{
          name: 'Desktops',
          data: [],
        }],
        xaxis: {
          categories: [],
        },
      },
    }
  },
  computed: {
    regressionOptions() {
      const options = { ...this.baseRegressionOptions }
      options.yaxis = [
        {
          opposite: true,
          title: {
            text: this.regressionSeries.length ? this.regressionSeries[0].name : '',
          },
          decimalsInFloat: 2,
        },
        {
          opposite: true,
          title: {
            text: this.regressionSeries.length ? this.regressionSeries[1].name : '',
          },
          decimalsInFloat: 2,
        },
        {
          title: {
            text: 'Rates',
          },
          decimalsInFloat: 2,
        },
      ]
      for (let i = 1; i <= this.regressionSeries.length - 3; i += 1) {
        options.yaxis.push({
          show: false,
          seriesName: this.regressionSeries[2].name,
        })
      }
      return options
    },
    distributorTrendOptions() {
      const options = { ...this.baseDistributorTrendOptions }
      options.xaxis.tickAmount = Math.floor(this.distributorTrendSeries[0].series[0].data.length / 3)
      return options
    },
    compBubbleSeries() {
      const series = []
      this.bubbleSeries.forEach(s => {
        const mins = s.data.reduce((prev, cur) => Math.min(prev, cur[2]), s.data[0][2])
        const maxs = s.data.reduce((prev, cur) => Math.max(prev, cur[2]), s.data[0][2])
        const range = maxs - mins
        const newData = []
        s.data.forEach(d => {
          newData.push([d[0], d[1], d[2] * range])
        })
        series.push({
          data: newData,
        })
      })
      return series
    },
  },
  created() {
    this.changeall()
  },
  methods: {
    loadRegression() {
      this.$http
        .get('test1.cfc?method=getRegressionData', {
          params: {
            strikestartdate: '2018-01-01',
            terminationstartdate: '2020-01-01',
            enddate: '2023-07-01',
            monthsperperiod: 1,
            pwm: 'default',
          },
        })
        .then(response => {
          this.regressionSeries = response.data.CHARTDATA.data
        })
    },
    loadDistributorTrends() {
      this.$http
        .get('report.cfc?method=getTrendData', {
          params: {
            type: 'maturity',
            productweightingmode: 'salesvolume',
            issuerid: 0,
            distributorid: 0,
            anymode: 'pairs',
          },
        })
        .then(response => {
          this.distributorTrendSeries = response.data.SUMMARYDATA.twoseries
        })
    },
    testSubIndexList() {
      this.$http.get('data.cfc?method=getSubIndexList', {
        params: {
          productweightingmode: 'equal',
        },
      })
        .then(response => {
          this.testSubIndexListResult = response.data
        })
    },
    testSubIndexData() {
      this.$http.get('data.cfc?method=getSubIndexData', {
        params: {
          productweightingmode: 'equal',
          indexlistdata: { underlying: [2255] },
        },
      })
        .then(response => {
          this.testSubIndexDataResult = response.data
        })
    },
    testDownloadProductReportPdf() {
      this.testDownloadProductReportPdfDisabled = true
      this.$http.get('report.cfc?method=getProductReportPdf&productid=33247682', { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          // the filename you want
          a.download = 'report.pdf'
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .finally(() => {
          this.testDownloadProductReportPdfDisabled = false
        })
    },
    testDownloadPortfolioReportPdf() {
      this.testDownloadPortfolioReportPdfDisabled = true
      this.$http.get('report.cfc?method=getPortfolioReportPdf&portfolioid=1', { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(response.data)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          // the filename you want
          a.download = 'report.pdf'
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .finally(() => {
          this.testDownloadPortfolioReportPdfDisabled = false
        })
    },
    testSpreadsheetUploadPrepare() {
      this.$http.post('portfolio.cfc?method=prepareUpload', new URLSearchParams({
        action: 'update',
      }))
        .then(response => {
          this.spreadsheetUploadPrepareResult = response.data
        })
    },
    testSpreadsheetUploadPerform() {
      this.$http.post('portfolio.cfc?method=performUpload', new URLSearchParams({
        uploadId: this.spreadsheetUploadPrepareResult.uploadId,
      }))
        .then(response => {
          this.spreadsheetUploadPerformResult = response.data
        })
    },
    testCreatePortfolio() {
      this.$http.post('portfolio.cfc?method=createPortfolio', new URLSearchParams({
        portfolioName: 'My new portfolio',
        productIds: [1, 2, 3],
      }))
    },
    changeall() {
      this.loadRegression()
      this.loadDistributorTrends()
      this.changed()
      this.changed2()
    },
    changed() {
      this.$http
        .get(`test1.cfc?method=getpiedata&first=${this.first}`)
        .then(response => { this.underlyings = response.data })
    },
    changed2() {
      this.$http
        .get(`test1.cfc?method=getlinedata2&scale1=${this.scale}&scale2=0`)
        .then(response => {
          this.linedata.series = response.data.series
          this.linedata.xaxis = response.data.xaxis
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
