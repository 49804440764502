<template>
  <b-card
    v-if="data"
    class="earnings-card"
    :title="data.title"
  >
    <!-- chart -->
    <vue-apex-charts
      ref="chart"
      height="300"
      :options="dynamicoptions"
      :series="data.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lineChart: {
        chartOptions: {

          chart: {
            type: 'line',
            toolbar: {
              show: true,
            },
            events: {
              animationEnd(chartContext) {
                chartContext.toggleDataPointSelection(0)
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [2, -3, 8],
          stroke: { curve: 'straight' },
          grid: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },

          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
          },

        },
      },
    }
  },
  computed: {
    dynamicoptions() {
      const options = { ...this.lineChart.chartOptions }
      options.xaxis = this.data.xaxis
      return options
    },
  },
}
</script>
